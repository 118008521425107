@import './assets/fonts/Poppins/stylesheet.css';
@import './assets/fonts/Aleo/stylesheet.css';
@import 'tailwindcss/tailwind.css';
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

html, body, #root {
    min-height: 100%;
    height: 100%;
}

:root {
    --scrollbar-color: #D3D8E1;
}

::-webkit-scrollbar {
    width: 4px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: var(--scrollbar-color);
}

.page-container {
    background: linear-gradient(-45deg, #f98b8b, #91b8bb, #a186ff);
}

.page-container.dark {
    background: linear-gradient(-45deg, #5d0000, #0a2729, #211449)
}